/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./headerlight"
import "./layout.css"
import "./footer.css"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        >
        <main>{children}</main>
        <div id="contact" className="Contact">
          <div className="ContactWrapper">
          <div className="ContactGroup">
          <h1>denizonmus@tutanota.com</h1>
            <a href="mailto:denizonmus@tutanota.com">Email Me</a>
            
            
          </div>
          </div>
          </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
