

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import HeaderLight from "../components/headerlight"



const IndexPage = () => (
  
  <Layout>
          <HeaderLight/>
          
    
    <div className={styles.Hero}>
      <div className={styles.HeroGroup}>
      <h1>Hello, I am Deniz.</h1>
      <h2> Welcome!</h2>
      <p>I hope you will enjoy your stay.</p>
      
      </div>
    </div>

  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
