import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./header.css"

const HeaderLight = ({ siteTitle }) => (
  <div className="Header">
    <div className="HeaderGroup">
{/*       <Link to="/"><img src={logo} width ="60" /></Link> */}
      <Link to="/">Home</Link>
      <Link to="/work">Work</Link>
      <Link to="/about">Info</Link>
      
    </div>
    
  </div>
)


export default HeaderLight
